import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useStoreState } from "easy-peasy";
import getHeaders from "../constants/utils";
import constants from "../constants/constants";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";

const LeaveReport = () => {
    const [records, setRecords] = useState([]);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const user = useStoreState((state) => state.loginModel.user);
    const [leaveReportArrayUniqueKeyNames, setLeaveReportArrayUniqueKeyNames] = useState([]);

    const getFirstDayOfMonth = () => {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    };

    const getCurrentDate = () => {
        return new Date();
    };
    const [from_date, setFromDate] = useState(getFirstDayOfMonth());
    const [to_date, setToDate] = useState(getCurrentDate());

    const formatDate = (value) => {
        return value?.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };

    useEffect(() => {
        setIsLoading(true);
        getPendingRequests();
    }, []);

    useEffect(() => {
        if (from_date && to_date) {
            setIsLoading(true);
            getLeaveReport();
        }
    }, [from_date, to_date]);

    const getLeaveReport = () => {
        const payload = {
            from_date: formatDate(from_date),
            to_date: formatDate(to_date),
        };
        axios
            .post(constants.URL.LEAVE_REPORT + "/" + user?.org_id?._id, payload, { headers: getHeaders() })
            .then((resp) => {
                const results = resp?.data?.results;
                setLeaveReportArrayUniqueKeyNames(results?.leaveReportArrayUniqueKeyNames || []);
                setRecords(results?.leaveReportArray || []);
            })
            .catch((e) => {
                console.error(e);
                setRecords([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getPendingRequests = () => {
        const payload = {
            from_date: from_date,
            to_date: to_date,
        };
        axios
            .post(constants.URL.GET_LEAVE_REPORT + "/" + user?.org_id?._id, payload, { headers: getHeaders() })
            .then((resp) => {
                setPendingRequests(resp?.data?.results);
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            <div className="main-report">
                <div className="grid col-8">
                    <div className="col-12 mt-3">
                        <div className="md:flex flex-wrap align-items-center justify-content-between mb-3">
                            <h6 className="blue_bg_heading md:mb-0">All Employee</h6>
                            <div className="md:inline-flex flex flex-wrap align-items-center justify-content-center">
                                <label className="cal-text mr-2">From</label>
                                <Calendar
                                    name="from"
                                    value={from_date}
                                    style={{ width: "135px", paddingRight: "1rem" }}
                                    onChange={(e) => {
                                        setFromDate(e.value);
                                        setToDate(null);
                                    }}
                                    dateFormat="dd/mm/yy"
                                    showIcon
                                />
                                <label className="cal-text mr-2">To</label>
                                <Calendar name="to" value={to_date} style={{ width: "135px", paddingRight: "1rem" }} onChange={(e) => setToDate(e.value)} dateFormat="dd/mm/yy" minDate={from_date} showIcon />
                            </div>
                            <Divider />
                        </div>
                        <div className="mt-3">
                            <div className="card">
                                <DataTable value={records} loading={isLoading}>
                                    <Column field="Employee_name" header="Name"></Column>
                                    {leaveReportArrayUniqueKeyNames.map((keyName) => (
                                        <Column key={keyName} field={keyName} header={keyName}></Column>
                                    ))}
                                    <Column field="total_leave_days" header="Total"></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid col-4">
                    <div className="col-12 mt-3">
                        <div className="md:flex flex-wrap align-items-center justify-content-between mb-3">
                            <h6 className="pending_head md:mb-4">Pending Request</h6>
                            <Divider />
                        </div>
                        <div className="mt-3">
                            <div className="card">
                                <DataTable value={pendingRequests} loading={isLoading} responsiveLayout="scroll">
                                    <Column
                                        field="user_id.first_name"
                                        header="Name"
                                        body={(details) => {
                                            const fromDate = new Date(details?.from_date).toLocaleDateString();
                                            const toDate = new Date(details?.to_date).toLocaleDateString();
                                            return (
                                                <div>
                                                    <div className="pending_name">{details?.user_id?.first_name}</div>
                                                    <div
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#747588",
                                                            paddingTop: "0.5rem",
                                                        }}
                                                    >
                                                        {fromDate === toDate ? fromDate : `${fromDate} to ${toDate}`}
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    ></Column>
                                    <Column field="status" header="Status" bodyStyle={{ color: "#6366F1"}}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeaveReport;
