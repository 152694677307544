import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import axios from "axios";
import { useStoreState } from "easy-peasy";
import constants from "../constants/constants";
import getHeaders from "../constants/utils";
import { Dropdown } from "primereact/dropdown";

const PayslipRelease = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [releasedPayslipList, setReleasedPayslipList] = useState();
    const [dropdownList, setDropdownList] = useState();
    const user = useStoreState((state) => state.loginModel.user);

    useEffect(() => {
        getReleasedPayslipList();
    }, []);

    const getReleasedPayslipList = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.PAYSLIP_RELEASE + "/" + user?.org_id?._id, { headers: getHeaders() })
            .then((resp) => {
                setReleasedPayslipList(resp?.data?.results);
                
                const currentDate = new Date();
                const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                let year = currentDate.getFullYear();
                let month = currentDate.getMonth(); // Current month (0-based index)

                const list = [];

                if (resp?.data?.results?.length === 0) {

                    // Get the previous month
                    let prevMonth = month - 1;
                    let prevYear = year;

                    if (prevMonth < 0) {
                        prevMonth = 11; // December
                        prevYear -= 1;  // Previous year
                    }

                    list.push(`${months[prevMonth]}-${prevYear}`);
                    return setDropdownList(list);
                } else {
                    // Already released payslip months
                    const releasedMonths = resp?.data?.results?.map((item) => item.payslipReleaseMonth);

                    const lastReleased = releasedMonths[releasedMonths.length - 1];

                    // Extract last released month and year
                    if (!lastReleased) {
                        return setDropdownList(list); // Handle case when no months are released yet
                    }

                    const [lastReleasedMonth, lastReleasedYear] = lastReleased.split("-");
                    // console.log("Last Released Month:", lastReleasedMonth, "Year:", lastReleasedYear);

                    const lastReleasedMonthIndex = months.indexOf(lastReleasedMonth);
                    if (lastReleasedMonthIndex === -1) {
                        // console.error(`Invalid month name: ${lastReleasedMonth}`);
                        return setDropdownList(list);
                    }

                    let tempMonth = lastReleasedMonthIndex + 1; // Start after the last released month
                    let tempYear = parseInt(lastReleasedYear, 10);

                    // Adjust tempMonth and tempYear if tempMonth exceeds 11
                    if (tempMonth > 11) {
                        tempMonth = 0;
                        tempYear += 1;
                    }
                    // console.log("Starting tempMonth:", tempMonth, "tempYear:", tempYear);

                    // Loop until we reach the current month and year
                    while (tempYear < year || (tempYear === year && tempMonth < month)) {
                        const monthName = months[tempMonth];
                        if (!monthName) {
                            // console.error(`Invalid tempMonth index: ${tempMonth}`);
                            break; // Exit the loop to prevent infinite or erroneous iterations
                        }

                        const monthYear = `${monthName}-${tempYear}`;
                        // console.log("Processing Month-Year:", monthYear);

                        if (!releasedMonths.includes(monthYear)) {
                            list.push(monthYear); // Add to list if not already released
                            // console.log("Added to list:", monthYear);
                        } else {
                            console.log("Already released, skipping:", monthYear);
                        }

                        tempMonth++;

                        // If we exceed December, reset to January and increment the year
                        if (tempMonth > 11) {
                            tempMonth = 0;
                            tempYear++;
                            // console.log("Reset tempMonth to:", tempMonth, "Incremented tempYear to:", tempYear);
                        }
                    }

                    setDropdownList(list);
                }
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }
    

    const onSubmit = (data) => {
        // console.log(data);
        const payload = {
            org_id: user?.org_id?._id,
            payslipReleaseMonth: data?.monthOfRelease,
        }

        setIsLoading(true);
        axios.post(constants.URL.PAYSLIP_RELEASE, payload, { headers: getHeaders() })
            .then((resp) => {
                // console.log(resp);
                toast.current.show({ severity: "success", summary: "Success", detail: "Payslip Released Successfully" });
                reset();
                getReleasedPayslipList();
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    };

    const defaultValues = { monthOfRelease: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const handleDelete = (data) => {
        console.log(data);
        setIsLoading(true);
        axios.delete(constants.URL.PAYSLIP_RELEASE + "/" + data?._id, { headers: getHeaders() })
            .then((resp) => {
                getReleasedPayslipList();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item) => {
        confirmDialog({
            message: 'Do you want to delete this monthOfRelease?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleDelete(item),
            // reject,
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            draggable: false,
            // className: "confirm_dilog",
        });
    };

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                <div className="pt-3">
                    <div className="blue_bg px-4 py-3">
                        <h6 className="blue_bg_heading mb-0">Payslip Release</h6>
                    </div>
                    <div className="p-fluid formgrid grid p-4 pb-0 align-items-center">
                        <div className="field col-12 md:col-6 lg:col-4">
                            <label htmlFor="monthOfRelease">
                                Payslip Release Month<span className="p-error">*</span>
                            </label>
                            <span className="w-full">
                                <Controller
                                    name="monthOfRelease"
                                    control={form.control}
                                    rules={{ required: "Month is required." }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown options={dropdownList} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("monthOfRelease")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-8 flex justify-content-end mb-0 lg:mb-4">
                            <div className="">
                                <Button icon="pi pi-check" size="small" loading={isLoading} label="Release" className="btn-yellow" />
                            </div>
                        </div>
                    </div>

                    <div className="blue_bg px-4 py-3">
                        <h4 className="blue_bg_heading mb-0">Payslip Released Month List</h4>
                    </div>
                    <div className="mt-4">
                        {releasedPayslipList ? releasedPayslipList?.map((item, index) => {
                            return (
                                <div className='mb-2'>
                                    <div className="card-hover px-4 py-2 flex justify-content-between align-items-center">
                                        <h4 className="release_list mb-0">{index + 1}. {item?.payslipReleaseMonth}</h4>
                                        {/* <i className="pi pi-trash" onClick={() => confirm(item)}></i> */}
                                    </div>
                                </div>
                            )
                        }) : <div className="px-4 mb-4">No Released Payslip found</div>}
                    </div>
                </div>
            </form>
        </>
    );
};
export default PayslipRelease;
